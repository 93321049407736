import { WEEK_DAY_FORMAT, DATE_FORMAT } from '_/config/date';
import { formatDate } from '_/helpers/formatDate';
import { isIntervalInvalidTime } from '_/helpers/isIntervalInvalid';
import { SpaceType } from '_/services/models/enums/space-type.enum';
import { EventType } from '_/services/models/events.model';
import {
  add,
  startOfDay,
  endOfYear,
  parse,
  addDays,
  subDays,
  getHours,
  getMinutes,
  parseISO,
  isAfter,
  isWithinInterval,
  isToday,
  subMilliseconds,
  addMilliseconds,
  isBefore,
} from 'date-fns';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { AppState, Platform } from 'react-native';

import { DateContextData, EventDate, Time } from '../services/models/date.model';
import { useLanguage } from './LanguageContext';
import { useOrganizationContext } from './OrganizationContext';
import { useSpaceContext } from './SpaceContext';

const DateContext = createContext<DateContextData>({} as DateContextData);

type DateType = {
  children?: React.ReactNode;
};

export const DateProvider: React.FC<DateType> = ({ children }) => {
  const [today, setToday] = useState(startOfDay(new Date()));
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedFinalDay, setSelectedFinalDay] = useState('');
  const [start, setStart] = useState<Time>({} as Time);
  const [timePickerVisible, setTimePickerVisible] = useState(false);
  const [conflitedEvents, setConflitedEvents] = useState<EventType[]>([]);
  const [slotWithConflicts, setSlotWithConflicts] = useState<string[]>([]);
  const [end, setEnd] = useState<Time>({} as Time);
  const [isEndOfYear, setIsEndOfYear] = useState(true);
  const [selectedEventDate, setSelectedEventDate] = useState<EventDate>({} as EventDate);
  const [hasConflict, setHasConflict] = useState(false);
  const [maxDate, setMaxDate] = useState<Date | undefined>();
  const [updateEventDate, setUpdateEventDate] = useState<EventDate | undefined>(undefined);
  const { currentSpace, spaceType } = useSpaceContext();
  const { organizationData } = useOrganizationContext();
  const { language, locale } = useLanguage();

  const appState = useRef(AppState.currentState);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', _handleAppStateChange);

    return () => {
      subscription.remove();
    };
  }, []);

  const _handleAppStateChange = (nextAppState: any) => {
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      setToday(startOfDay(new Date()));
    }

    appState.current = nextAppState;
  };

  useEffect(() => {
    if (currentSpace?.advancedScheduleInDays || organizationData?.advancedScheduleInDays) {
      setMaxDate(
        addDays(
          today,
          currentSpace?.advancedScheduleInDays ||
            (organizationData?.advancedScheduleInDays as number)
        )
      );
    } else {
      setMaxDate(undefined);
    }
  }, [currentSpace, organizationData?.advancedScheduleInDays, today]);

  const checkOpenCloseHourMin = useCallback(
    (
      eventStart = selectedEventDate.start,
      eventEnd = selectedEventDate.end,
      openAt = currentSpace.openAtHourMin,
      closeAt = currentSpace.closeAtHourMin
    ) => {
      if (eventStart && eventEnd) {
        let isAfterOpen = false;
        let isBeforeClose = false;

        if (openAt) {
          const openHourMin = openAt.split(':');

          const open = add(new Date(parseISO(formatDate(startOfDay(eventStart), DATE_FORMAT))), {
            hours: +openHourMin[0],
            minutes: +openHourMin[1],
          });

          isAfterOpen = isAfter(open, eventStart);
        }

        if (closeAt) {
          const closeHourMin = closeAt.split(':');

          const close = add(new Date(parseISO(formatDate(startOfDay(eventEnd), DATE_FORMAT))), {
            hours: +closeHourMin[0],
            minutes: +closeHourMin[1],
          });

          isBeforeClose = isBefore(close, eventEnd);
        }

        return isAfterOpen || isBeforeClose;
      }

      return false;
    },
    [
      selectedEventDate.start,
      selectedEventDate.end,
      currentSpace.openAtHourMin,
      currentSpace.closeAtHourMin,
    ]
  );

  const defaultTime = useMemo(() => {
    if (spaceType === SpaceType.WORK) {
      return {
        startHour: 8,
        startMinute: 0,
        endHour: 18,
        endMinute: 0,
      };
    }
    return {
      startHour: new Date().getHours(),
      startMinute: new Date().getMinutes(),
      endHour: new Date().getHours(),
      endMinute: new Date().getMinutes() + 30,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceType, today]);

  useEffect(() => {
    setSelectedEventDate({
      start: add(today, {
        hours: currentSpace.metadata?.startHour ?? defaultTime.startHour,
        minutes: currentSpace.metadata?.startMinute ?? defaultTime.startMinute,
      }),
      end: add(today, {
        hours: currentSpace.metadata?.endHour ?? defaultTime.endHour,
        minutes: currentSpace.metadata?.endMinute ?? defaultTime.endMinute,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSpace.metadata?.endHour,
    currentSpace.metadata?.endMinute,
    currentSpace.metadata?.startHour,
    currentSpace.metadata?.startMinute,
    currentSpace.type,
  ]);

  useEffect(() => {
    if (selectedEventDate) {
      setStart({
        hour: getHours(selectedEventDate.start),
        minute: getMinutes(selectedEventDate.start),
      });
      setEnd({
        hour: getHours(selectedEventDate.end),
        minute: getMinutes(selectedEventDate.end),
      });
    }
  }, [selectedEventDate]);

  useEffect(() => {
    if (selectedDay) {
      setSelectedEventDate({
        start: add(parseISO(selectedDay), {
          hours: start.hour,
          minutes: start.minute,
        }),
        end: add(parseISO(selectedDay), {
          hours: end.hour,
          minutes: end.minute,
        }),
      });
    }
  }, [spaceType, selectedDay, start.hour, start.minute, end.hour, end.minute]);

  const dayOfWeek = useMemo(() => {
    if (selectedDay) {
      return formatDate(parse(selectedDay, DATE_FORMAT, new Date()), WEEK_DAY_FORMAT, {
        locale,
      });
    }
  }, [locale, selectedDay]);

  const dayOfMonth = useMemo(() => {
    if (selectedDay) {
      return formatDate(parse(selectedDay, DATE_FORMAT, new Date()), language.format?.day_month, {
        locale,
      });
    }
  }, [locale, language, selectedDay]);

  const isIntervalInvalid = useMemo(() => {
    return isIntervalInvalidTime({ date: selectedEventDate });
  }, [selectedEventDate]);

  const hasStartTimeBeforeCurrent = useMemo(() => {
    return isIntervalInvalidTime({
      date: selectedEventDate,
      type: 'isBeforeCurrentTime',
      updateEvent: updateEventDate,
    });
  }, [selectedEventDate, updateEventDate]);

  const selectedFinalDayToDate = useMemo(() => {
    if (isEndOfYear) {
      return endOfYear(new Date());
    } else {
      return parse(selectedFinalDay, DATE_FORMAT, new Date());
    }
  }, [selectedFinalDay, isEndOfYear]);

  const handleChangeSelectedDay = useCallback(
    (type: 'add' | 'sub') => {
      const date = parse(selectedDay, DATE_FORMAT, new Date());
      const newDate = type === 'add' ? addDays(date, 1) : subDays(date, 1);
      const startHours = getHours(selectedEventDate.start);
      const startMinutes = getMinutes(selectedEventDate.start);
      const endHours = getHours(selectedEventDate.end);
      const endMinutes = getMinutes(selectedEventDate.end);

      setSelectedDay(formatDate(newDate, DATE_FORMAT));
      setSelectedEventDate({
        start: add(newDate, {
          hours: startHours,
          minutes: startMinutes,
        }),
        end: add(newDate, { hours: endHours, minutes: endMinutes }),
      });
    },
    [selectedDay, selectedEventDate.end, selectedEventDate.start]
  );

  const extraMeetingTime = useMemo(() => {
    return currentSpace.createExtraMeetingWithDuration
      ? currentSpace.createExtraMeetingWithDuration
      : 0;
  }, [currentSpace.createExtraMeetingWithDuration]);

  const hasExtraMeetingTime = useMemo(() => {
    if (extraMeetingTime > 0) {
      return true;
    } else {
      return false;
    }
  }, [extraMeetingTime]);

  const reset = useCallback(() => {
    setSelectedEventDate({
      start: add(today, {
        hours: currentSpace.metadata?.startHour ?? defaultTime.startHour,
        minutes: currentSpace.metadata?.startMinute ?? defaultTime.startMinute,
      }),
      end: add(today, {
        hours: currentSpace.metadata?.endHour ?? defaultTime.endHour,
        minutes: currentSpace.metadata?.endMinute ?? defaultTime.endMinute,
      }),
    });
    setSelectedDay('');
    setSelectedFinalDay('');
    setIsEndOfYear(true);
    setHasConflict(false);
    setTimePickerVisible(false);
    setConflitedEvents([]);
  }, [
    currentSpace.metadata?.endHour,
    currentSpace.metadata?.endMinute,
    currentSpace.metadata?.startHour,
    currentSpace.metadata?.startMinute,
    defaultTime.endHour,
    defaultTime.endMinute,
    defaultTime.startHour,
    defaultTime.startMinute,
    today,
  ]);

  const checkIfThereIsConflict = useCallback(
    (
      eventDate: {
        start: Date;
        end: Date;
      },
      events: EventType[]
    ) => {
      if (eventDate.end <= eventDate.start) {
        return;
      }
      const hasEventsOnSelectedDateInterval = events?.filter((event) => {
        if (isAfter(parseISO(event.startDate), parseISO(event.endDate))) {
          return;
        }

        const endDate = add(eventDate.end, {
          minutes: extraMeetingTime,
        });
        return (
          isWithinInterval(eventDate.start, {
            start: parseISO(event.startDate),
            end: subMilliseconds(parseISO(event.endDate), 1),
          }) ||
          isWithinInterval(endDate, {
            start: addMilliseconds(parseISO(event.startDate), 1),
            end: parseISO(event.endDate),
          }) ||
          isWithinInterval(parseISO(event.startDate), {
            start: addMilliseconds(eventDate.start, 1),
            end: subMilliseconds(eventDate.end, 1),
          }) ||
          isWithinInterval(parseISO(event.endDate), {
            start: addMilliseconds(eventDate.start, 1),
            end: subMilliseconds(eventDate.end, 1),
          })
        );
      });
      setConflitedEvents(hasEventsOnSelectedDateInterval || []);
      const conflictedSlotIds = hasEventsOnSelectedDateInterval?.map((event) => event.slotId);
      setSlotWithConflicts(conflictedSlotIds);
      setHasConflict(hasEventsOnSelectedDateInterval?.length > 0);
    },
    [extraMeetingTime]
  );

  const handleEventDate = useCallback(
    (
      date: Date,
      selectedHour: number,
      meetingEvents?: EventType[],
      startDate?: Date,
      endDate?: Date
    ) => {
      const selectedDate =
        Platform.OS === 'web' ? (startDate as Date) : add(date, { hours: selectedHour });
      if (isToday(selectedDate) && selectedDate < new Date()) {
        const eventDate = {
          start: new Date(),
          end: add(new Date(), { minutes: 30 }),
        };
        checkIfThereIsConflict(eventDate, meetingEvents as EventType[]);
        setSelectedEventDate(eventDate);
        return;
      }
      checkIfThereIsConflict(
        { start: selectedDate, end: add(selectedDate, { minutes: 30 + extraMeetingTime }) },
        meetingEvents as EventType[]
      );

      setSelectedEventDate({
        start: selectedDate,
        end:
          Platform.OS === 'web'
            ? add(endDate as Date, {
                minutes: extraMeetingTime,
              })
            : add(selectedDate, {
                minutes: 30 + extraMeetingTime,
              }),
      });
      setSelectedEventDate({
        start: selectedDate,
        end: Platform.OS === 'web' ? (endDate as Date) : add(selectedDate, { minutes: 30 }),
      });
    },
    [checkIfThereIsConflict, extraMeetingTime]
  );

  return (
    <DateContext.Provider
      value={{
        today,
        selectedDay,
        dayOfMonth,
        dayOfWeek,
        selectedFinalDay,
        selectedFinalDayToDate,
        isEndOfYear,
        maxDate,
        setSelectedDay,
        setSelectedFinalDay,
        setIsEndOfYear,
        checkIfThereIsConflict,
        handleChangeSelectedDay,
        isIntervalInvalid,
        hasStartTimeBeforeCurrent,
        reset,
        extraMeetingTime,
        hasExtraMeetingTime,
        hasConflict,
        conflitedEvents,
        setHasConflict,
        selectedEventDate,
        setSelectedEventDate,
        handleEventDate,
        timePickerVisible,
        slotWithConflicts,
        setSlotWithConflicts,
        setTimePickerVisible,
        checkOpenCloseHourMin,
        setUpdateEventDate,
      }}
    >
      {children}
    </DateContext.Provider>
  );
};

export function useDateContext(): DateContextData {
  const context = useContext(DateContext);

  if (!context) {
    throw new Error('useDateContext must be used within an DateProvider');
  }

  return context;
}
