import { ConfirmReserveModal, EventModal } from '_/components';
import { alphaColor, colors } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { RecurrenceType } from '_/services/models/enums/recurrence-type.enum';
import { EventType, EventsResponse } from '_/services/models/events.model';
import { SlotsModel } from '_/services/models/slots.model';
import {
  format,
  parse,
  startOfWeek,
  getDay,
  parseISO,
  endOfDay,
  startOfDay,
  formatISO,
  getHours,
} from 'date-fns';
import localeEn from 'date-fns/locale/en-US';
import localeEs from 'date-fns/locale/es';
import localePt from 'date-fns/locale/pt-BR';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { dateFnsLocalizer, Calendar } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import { ModalProps, SelectedEvent } from './interfaces';

import './styles.module.css';

const WebWeekCalendar: React.FC<ModalProps> = ({ visible, slot }) => {
  const [recurrenceType, setRecurrenceType] = useState<RecurrenceType>(RecurrenceType.ONCE);
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent | undefined>();

  const { selectedDay, setHasConflict, handleEventDate, checkOpenCloseHourMin } = useDateContext();
  const { isSlotModalVisible, setIsSlotModalVisible } = useSlotContext();
  const { spaceId } = useSpaceContext();
  const { setRecurrenceStr, meetingEvents, getSpaceEvents, setMeetingReservations } =
    useEventContext();

  const { t } = useTranslation();

  const { currentSpace } = useSpaceContext();

  const locales = {
    'en-US': localeEn,
    es: localeEs,
    'pt-BR': localePt,
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  async function getReservations() {
    setLoading(true);
    const data = await getSpaceEvents({
      spaceId,
      startAtTime: startOfDay(parseISO(selectedDay)),
      endAtTime: endOfDay(parseISO(selectedDay)),
    });
    const slotEvents = data.slots.find((s: SlotsModel) => s.id === slot.id)?.events || [];
    setMeetingReservations(slotEvents as EventsResponse[]);
    setLoading(false);
  }

  useEffect(() => {
    if (visible) {
      getReservations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, visible]);

  const gridEvents = useMemo(() => {
    const events = meetingEvents?.map((event: EventType) => {
      return {
        title: event.parentEventId ? t('reportsScreen.CLEANING') : event.name,
        start: parseISO(event.startDate),
        end: parseISO(event.endDate),
        color: event.color,
        parentEventId: event.parentEventId,
      };
    });
    return events || [];
  }, [meetingEvents, t]);

  const handleCloseConfirmationModal = useCallback(() => {
    setIsSlotModalVisible('');
    setHasConflict(false);
    setRecurrenceType(RecurrenceType.ONCE);
  }, []);

  const onGridClick = useCallback(
    (info: any) => {
      if (checkOpenCloseHourMin(info.start, info.end)) {
        showMessage({
          message: t('error'),
          description: t('workstationScreen.errorMessages.checkOpenCloseHourMinError', {
            openAt: currentSpace?.openAtHourMin ?? '00:00',
            closeAt: currentSpace?.closeAtHourMin ?? '23:59',
          }),
          backgroundColor: colors.errorRed,
        });
        return;
      }

      handleEventDate(
        startOfDay(info.start),
        getHours(info.start),
        meetingEvents,
        info.start,
        info.end
      );
      setRecurrenceStr('');
      setRecurrenceType(RecurrenceType.ONCE);
      setIsSlotModalVisible(slot.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleEventDate, meetingEvents]
  );

  const HandleEventModal = useCallback(() => {
    if (selectedEvent) {
      return (
        <EventModal
          eventModalVisible
          onBackdropPress={() => setSelectedEvent(undefined)}
          onBackButtonPress={() => setSelectedEvent(undefined)}
          onSwipeStart={() => setSelectedEvent(undefined)}
          id={selectedEvent.title}
          name={selectedEvent.title}
          startDate={formatISO(selectedEvent.start)}
          endDate={formatISO(selectedEvent.end)}
        />
      );
    }
    return null;
  }, [selectedEvent]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <HandleEventModal />
      {loading ? (
        <ActivityIndicator color={colors.black} size={35} style={{ marginTop: 20 }} />
      ) : (
        <Calendar
          selectable
          localizer={localizer}
          onSelectSlot={onGridClick}
          events={gridEvents}
          date={parseISO(selectedDay)}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, maxWidth: 550, width: '100%' }}
          defaultView="day"
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.color,
            },
          })}
        />
      )}
      {isSlotModalVisible === slot.id && (
        <ConfirmReserveModal
          onBackdropPress={handleCloseConfirmationModal}
          onBackButtonPress={handleCloseConfirmationModal}
          onSwipeStart={handleCloseConfirmationModal}
          slot={slot}
          recurrenceType={recurrenceType}
          setRecurrenceType={setRecurrenceType}
        />
      )}
    </>
  );
};

export default WebWeekCalendar;
